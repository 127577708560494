import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { API } from "../../../../modules/Api";
import ask_loader1 from "../../../../assets/img_easy/ask_loader1.png";
import ImageCropper from "../../../../components/ImageCropper";
import crowding from "../../../../assets/img/PatientsContent/crowding.png";
import cross_bite from "../../../../assets/img/PatientsContent/cross_bite.png";
import deep_bite from "../../../../assets/img/PatientsContent/deep_bite.png";
import open_bite from "../../../../assets/img/PatientsContent/open_bite.png";
import inclination from "../../../../assets/img/PatientsContent/inclination.png";
import overjet from "../../../../assets/img/PatientsContent/overjet.png";
import rotation from "../../../../assets/img/PatientsContent/rotation.png";
import spaces from "../../../../assets/img/PatientsContent/spaces.png";
import {
  Dialog,
  Paper,
  TextField,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from "@material-ui/core";
import "./index.scss";
import { useHistory, useLocation } from "react-router";
import { RATE_SMILE_DIALOG_HASH } from "../../../../constants";
import Logger from "../../../../modules/Logger";

function AskSmileAssessmentDialog() {
  const { t: _t } = useTranslation();

  const [malocclusion, setMalocclusion] = useState({});
  function toggleMalocclusion(e) {
    if (malocclusion[e.target.value]) {
      setMalocclusion((prev) => {
        let current = { ...prev };
        delete current[e.target.value];
        return current;
      });
    } else {
      setMalocclusion((prev) => ({ ...prev, [e.target.value]: true }));
    }
  }

  const SmileAssessmentSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required(_t("Обязательное поле")),
      city: Yup.string().required(_t("Обязательное поле")),
      phone_number: Yup.string().required(_t("Обязательное поле")),
      request_text: Yup.string().required(_t("Обязательное поле")),
      email: Yup.string().email(_t("Некорректный email")).required(_t("Обязательное поле")),
    });
  };

  const defaultStaticImages = [
    {
      staticImageId: 1,
      title: _t("Зубы спереди"),
      imgPreview: ask_loader1,
      imgUpload: null,
    },
  ];

  const [staticImages, setStaticImages] = useState(defaultStaticImages);

  function staticImageAction(e, currentItem) {
    if (currentItem.imgUpload) {
      e.preventDefault();
      setStaticImages((prev) => [
        ...prev.map((el) => (el.staticImageId === currentItem.staticImageId ? { ...el, imgUpload: null } : el)),
      ]);
    }
  }

  function staticImageAttach(e, id) {
    const file = e.target.files[0];
    e.target.value = "";
    e.target.type = "";
    e.target.type = "file";

    if (e.target.files && file) {
      fileReader(file).then((fileItem) => {
        setStaticImages((prev) => [
          ...prev.map((el) => (el.staticImageId === id ? { ...el, imgUpload: fileItem.file } : el)),
        ]);
      });
    }
  }

  function fileReader(file) {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.onloadend = (e) => {
        resolve({ file: e.target.result, name: file.name });
      };
      reader.readAsDataURL(file);
    });
  }

  const [isSending, setIsSending] = useState(false);

  const [email, setEmail] = useState("");

  const [smileAssessmentStep, setSmileAssessmentStep] = useState(false);

  function sendSmile({ name, city, phone_number, email, request_text, image_1 }) {
    setIsSending(true);

    const formData = new FormData();
    formData.set("name", name);
    formData.set("city", city);
    formData.set("phone_number", phone_number);
    formData.set("email", email);
    formData.set("request_text", request_text);

    formData.set("malocclusion", JSON.stringify(malocclusion));
    formData.append("image_1", image_1);

    const options = { headers: { "Content-Type": "multipart/form-data" } };

    API.post("/smileassessment/new", formData, options)
      .then(() => {
        setStaticImages(defaultStaticImages);
        setEmail(email);
        setMalocclusion({});
        setIsSending(false);
        setSmileAssessmentStep(true);
      })
      .catch((error) => {
        Logger.error("error", error);
        Logger.error("formData", formData);
      });
  }

  const { push } = useHistory();
  const { hash } = useLocation();

  const close = () => {
    push({ hash: "" });
  };

  return (
    <Dialog
      fullScreen
      open={hash === RATE_SMILE_DIALOG_HASH}
      onClose={close}
      aria-labelledby="dialog-askSmileAssessment"
      className="dialog-askSmileAssessment"
    >
      <Button onClick={close} variant="outlined" className="addArticle__close">
        {_t("Закрыть")}
      </Button>
      <div className="container">
        {smileAssessmentStep === false ? (
          <Formik
            initialValues={{
              name: "",
              city: "",
              phone_number: "",
              email: "",
              request_text: "",
              image_1: null,
            }}
            validationSchema={SmileAssessmentSchema}
            onSubmit={sendSmile}
          >
            {({ errors, setFieldValue }) => (
              <Form style={{ width: "100%" }}>
                <div className="simplePaper dialog-askSmileAssessment-simplePaper">
                  <div className="addArticle__title">{_t("Оценить улыбку")}</div>
                  <div className="dialog-askSmileAssessment-formWrap">
                    <Field name="name">
                      {({ field }) => (
                        <TextField
                          type="string"
                          label={_t("Имя")}
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          error={!!errors.name}
                          helperText={errors.name}
                          {...field}
                        />
                      )}
                    </Field>
                    <Field name="city">
                      {({ field }) => (
                        <TextField
                          type="string"
                          label={_t("Город")}
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          error={!!errors.city}
                          helperText={errors.city}
                          {...field}
                        />
                      )}
                    </Field>
                    <Field name="phone_number">
                      {({ field }) => (
                        <TextField
                          type="string"
                          label={_t("Тел/Моб")}
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          error={!!errors.phone_number}
                          helperText={errors.phone_number}
                          {...field}
                        />
                      )}
                    </Field>
                    <Field name="email">
                      {({ field }) => (
                        <TextField
                          type="email"
                          label={_t("Адрес электронной почты")}
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          error={!!errors.email}
                          helperText={errors.email}
                          {...field}
                        />
                      )}
                    </Field>
                  </div>
                  <p className="dialog-askSmileAssessment-simplePaper-text">
                    {_t("Напишите, что Вам не нравится в Вашей улыбке:")}
                  </p>
                  <div className="dialog-askSmileAssessment-simplePaper-textareaWrap">
                    <Field name="request_text">
                      {({ field }) => (
                        <TextField
                          margin="dense"
                          multiline
                          type="string"
                          error={!!errors.request_text}
                          helperText={errors.request_text}
                          rows="1"
                          rowsMax="40"
                          className="dialog-askSmileAssessment-simplePaper-textarea"
                          {...field}
                        />
                      )}
                    </Field>
                    <div className="dialog-askSmileAssessment-simplePaper-textarea-icon" />
                  </div>
                  <p className="dialog-askSmileAssessment-simplePaper-text">{_t("Выберите тип проблемы:")}</p>
                  <div className="dialog-askSmileAssessment-simplePaper-checkboxes">
                    <FormControl className="Step2-form-line" error={!!errors.malocclusion}>
                      <FormGroup className="Step2-form-radio">
                        <div className="Step2-form-radio-line">
                          <div className="Step2-form-imgBlock">
                            <img alt="" src={crowding} />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!malocclusion["CROWDING"]}
                                  onChange={toggleMalocclusion}
                                  value="CROWDING"
                                  color="default"
                                />
                              }
                              label={_t("Скученность")}
                            />
                          </div>
                          <div className="Step2-form-imgBlock">
                            <img alt="" src={spaces} />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!malocclusion["SPACES"]}
                                  onChange={toggleMalocclusion}
                                  value="SPACES"
                                  color="default"
                                />
                              }
                              label={_t("Тремы, диастемы")}
                            />
                          </div>
                          <div className="Step2-form-imgBlock">
                            <img alt="" src={overjet} />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!malocclusion["OVERJET"]}
                                  onChange={toggleMalocclusion}
                                  value="OVERJET"
                                  color="default"
                                />
                              }
                              label={_t("Сагиттальная щель")}
                            />
                          </div>
                          <div className="Step2-form-imgBlock">
                            <img alt="" src={inclination} />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!malocclusion["INCLINATION"]}
                                  onChange={toggleMalocclusion}
                                  value="INCLINATION"
                                  color="default"
                                />
                              }
                              label={_t("Инклинация")}
                            />
                          </div>
                        </div>
                        <div className="Step2-form-radio-line">
                          <div className="Step2-form-imgBlock">
                            <img alt="" src={deep_bite} />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!malocclusion["DEEP_BITE"]}
                                  onChange={toggleMalocclusion}
                                  value="DEEP_BITE"
                                  color="default"
                                />
                              }
                              label={_t("Глубокий прикус")}
                            />
                          </div>
                          <div className="Step2-form-imgBlock">
                            <img alt="" src={open_bite} />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!malocclusion["OPEN_BITE"]}
                                  onChange={toggleMalocclusion}
                                  value="OPEN_BITE"
                                  color="default"
                                />
                              }
                              label={_t("Открытый прикус")}
                            />
                          </div>
                          <div className="Step2-form-imgBlock">
                            <img alt="" src={cross_bite} />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!malocclusion["CROSS_BITE"]}
                                  onChange={toggleMalocclusion}
                                  value="CROSS_BITE"
                                  color="default"
                                />
                              }
                              label={_t("Перекрестный прикус")}
                            />
                          </div>
                          <div className="Step2-form-imgBlock">
                            <img alt="" src={rotation} />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!malocclusion["ROTATION"]}
                                  onChange={toggleMalocclusion}
                                  value="ROTATION"
                                  color="default"
                                />
                              }
                              label={_t("Ротация")}
                            />
                          </div>
                        </div>
                      </FormGroup>
                      <FormHelperText>{errors.malocclusion}</FormHelperText>
                    </FormControl>
                  </div>
                  <p className="dialog-askSmileAssessment-simplePaper-text">{_t("Загрузка фото:")}</p>
                  <div className="dialog-askSmileAssessment-simplePaper-text dialog-askSmileAssessment-simplePaper-textSmall">
                    {_t("Сфотографируйте свою улыбку в соответствии с рекомендациями")}
                    {staticImages.map(
                      (item) =>
                        item.imgUpload === null && (
                          <div key={item.staticImageId} className="pass-error">
                            {_t("Обязательное поле")}
                          </div>
                        ),
                    )}
                  </div>
                  <div className="dialog-askSmileAssessment-simplePaper-uploading">
                    {staticImages.map((item) => (
                      <div className="ProtocolPhotos__item" key={item.staticImageId}>
                        <div className="item-content">
                          <label className="item-content-label" onClick={(e) => staticImageAction(e, item)}>
                            <div className="item-content-action">
                              {!item.imgUpload ? (
                                <span className="addImage">+</span>
                              ) : (
                                <span className="removeImage">{_t("Удалить")}</span>
                              )}
                            </div>
                            {item.imgUpload ? (
                              <ImageCropper src={item.imgUpload} className="item-content-image" />
                            ) : (
                              <img className="item-content-image" src={item.imgPreview} alt="ProtocolPhotos" />
                            )}
                            <input
                              type="file"
                              name={`image_${item.staticImageId}`}
                              accept="image/*"
                              onChange={(event) => {
                                setFieldValue(`image_${item.staticImageId}`, event.currentTarget.files[0]);
                                staticImageAttach(event, item.staticImageId);
                              }}
                            />
                          </label>
                        </div>
                        <p className="item-title">{item.title}</p>
                      </div>
                    ))}
                  </div>
                  <p className="dialog-askSmileAssessment-simplePaper-text dialog-askSmileAssessment-simplePaper-textSmall">
                    {_t("После отправки данных вы получите ответ в течение двух дней")}
                  </p>
                  <Button
                    disabled={isSending}
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="btn askSmileAssessment-btn"
                  >
                    {_t("Отправить")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <Paper className="dialog-doctorRegister-confirm">
            <div className="checkEmail">
              <p className="checkEmail-title">{_t("Спасибо за то, что обратились к нам!")}</p>
              <p className="checkSmile-text">
                {_t(
                  "Вы получите оценку своей улыбки в течение нескольких дней на адрес электронной почты, который вы указали в заявке",
                )}
              </p>
              <p className="checkEmail-email">{email}</p>
              <p className="checkEmail-text">
                {_t(
                  "Если у вас есть дополнительные вопросы, вы можете связаться с нами по электронной почте {{address}} или по телефону {{phone}}",
                  { address: "", phone: "" },
                )}
              </p>
            </div>
          </Paper>
        )}
      </div>
    </Dialog>
  );
}

export default AskSmileAssessmentDialog;
