import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, TextField, IconButton, Button, CircularProgress } from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import { API } from "../../../../../modules/Api";
import * as Yup from "yup";
import CloseIcon from "@material-ui/icons/Close";
import "../index.scss";
import { useHistory, useLocation } from "react-router";
import { COOPERATION_DIALOG_HASH } from "../../../../../constants";

const ContactUs = () => {
  const { t: _t } = useTranslation();
  const { push } = useHistory();
  const { hash } = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const onlineSchema = () => {
    return Yup.object().shape({
      email: Yup.string().email(_t("Некорректный email")).required(_t("Обязательное поле")),
    });
  };

  const onlineSend = ({ email, text }) => {
    setIsLoading(true);
    API.post("/contact_us/new", { email, text })
      .then(() => close())
      .catch(() => {
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  };
  const close = () => {
    push({ hash: "" });
  };

  return (
    <Dialog fullWidth maxWidth="sm" className="footerDialog" open={hash === COOPERATION_DIALOG_HASH} onClose={close}>
      <div className="footerDialog__wrap">
        {isLoading && (
          <div className="overlay">
            <CircularProgress size={80} />
          </div>
        )}
        {isError && (
          <div className="overlay">
            <span>{_t("Что-то пошло не так. Сообщение не было отправлено. Попробуйте позже.")}</span>
          </div>
        )}
        <IconButton className="footerDialog__close" onClick={close}>
          <CloseIcon />
        </IconButton>
        <Formik
          initialValues={{
            email: "",
            text: "",
          }}
          validationSchema={onlineSchema}
          onSubmit={onlineSend}
          render={({ errors }) => (
            <Form style={{ maxWidth: "100%" }} className="form">
              <Field
                name="text"
                render={({ field }) => (
                  <TextField
                    margin="dense"
                    label={_t("Сообщение")}
                    multiline
                    fullWidth
                    rows="2"
                    rowsMax="40"
                    {...field}
                    className="textarea"
                  />
                )}
              />
              <Field
                name="email"
                render={({ field }) => (
                  <TextField
                    type="email"
                    label={_t("Email")}
                    margin="dense"
                    fullWidth
                    className="input"
                    error={!!errors.email}
                    helperText={errors.email}
                    {...field}
                  />
                )}
              />
              <div className="btnWrap">
                <Button type="submit" className="btn">
                  {_t("Отправить")}
                </Button>
              </div>
            </Form>
          )}
        />
      </div>
    </Dialog>
  );
};

export default ContactUs;
