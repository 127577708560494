import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import scrollToTop from "../../../modules/scrollToTop";
import AskSmileAssessmentDialog from "./AskSmileAssessmentDialog";
import "./ForPatients.scss";
import { useHistory } from "react-router";
import { RATE_SMILE_DIALOG_HASH } from "../../../constants";

function ForPatients() {
  const { t: _t } = useTranslation();
  const { push } = useHistory();

  useEffect(() => {
    scrollToTop();
  }, []);

  function openCooperationDialog() {
    push({ hash: RATE_SMILE_DIALOG_HASH });
  }

  return (
    <div className="forPatients">
      <section className="forPatients__main">
        <div className="container">
          <div className="forPatients__text">
            <h1>{_t("Для пациентов")}</h1>
            <p>
              {_t(
                "Самый современный и комфортный способ выравнивания зубов прозрачными капами - это системы элайнеров EASYBITE. Воспользуйтесь бесплатной консультацией наших специалистов.",
              )}
            </p>
            <div className="btnWrap">
              <Button onClick={openCooperationDialog} className="btn">
                {_t("Оценить улыбку")}
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section className="pPluses">
        <div className="containerSmall">
          <div className="pPluses__item">
            <div className="pPluses__pic">
              <img src={require("../../../assets/img_easy/patients1.png").default} alt="" />
            </div>
            <div className="pPluses__text">
              <p>{_t("Высокотехнологичное ортодонтическое лечение.")}</p>
              <p>{_t("Для изготовления твоего ортодонтического устройства используются:")}</p>
              <p>{_t("3D сканирование, 3D моделирование and 3D печать.")}</p>
            </div>
          </div>
          <div className="pPluses__item">
            <div className="pPluses__pic">
              <img src={require("../../../assets/img_easy/patients2.png").default} alt="" />
            </div>
            <div className="pPluses__text">
              <p>
                {_t(
                  "Ортодонтическое лечение элайнерами не ограничивает привычный образ жизни. Нет ограничений на прием пищи и развлечения.",
                )}
              </p>
            </div>
          </div>
          <div className="pPluses__item">
            <div className="pPluses__pic">
              <img src={require("../../../assets/img_easy/patients3.png").default} alt="" />
            </div>
            <div className="pPluses__text">
              <p>{_t("Комфортная ценовая политика.")}</p>
            </div>
          </div>
          <div className="pPluses__item">
            <div className="pPluses__pic">
              <img src={require("../../../assets/img_easy/patients4.png").default} alt="" />
            </div>
            <div className="pPluses__text">
              <p>{_t("Возможность увидеть конечный результат перед началом лечения.")}</p>
            </div>
          </div>
          <div className="pPluses__item">
            <div className="pPluses__pic">
              <img src={require("../../../assets/img_easy/patients5.png").default} alt="" />
            </div>
            <div className="pPluses__text">
              <p>{_t("Сертифицированные врачи для работы с Easybite.")}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="pWhy">
        <div className="containerSmall">
          <h2>{_t("Почему элайнеры лучший способ выравнивания зубов")}</h2>
          <p>
            {_t(
              "В ортодонтической стоматологии элайнеры для выравнивания зубов применяются все чаще, что объясняется удобством их использования как для пациентов, так и для врачей. Процессы установки, носки и ухода за элайнерами максимально просты, а сами элайнеры, при правильном ношении, способны исправлять даже сложные клинические случаи, не доставляя пациентам болевых ощущений и морального дискомфорта. Прозрачность элайнеров делает их ношение совершенно незаметным для окружающих, что позволяет эффективно устранять проблему, не привлекая дополнительное внимание окружающих.",
            )}
          </p>
        </div>
      </section>
      <section className="whatAreAligners">
        <div className="whatAreAligners__pic">
          <img src={require("../../../assets/img_easy/patients_what.png").default} alt="" />
        </div>
        <div className="containerSmall">
          <h2>{_t("Что такое элайнеры Easy Bite")}</h2>
          <p>
            {_t(
              "Изготавливаемые из прочного полимерного материала по индивидуальным оттискам зубов пациента, элайнеры Easy Bite способны полностью устранить большинство случаев искривления зубов и нарушения прикуса. Стоимость элайнеров, а также время их ношения по сравнению с брекетами будет существенно ниже в большинстве клинических случаев. Прочность и хорошее сопротивление деформации позволят пациенту не беспокоится о достижении желаемого результата.",
            )}
          </p>
        </div>
      </section>
      <section className="pWhat">
        <div className="container">
          <h2>{_t("Что такое оценка улыбки")}</h2>
          <div className="pWhat__wrap">
            <div className="pWhat__pic">
              <img src={require("../../../assets/img_easy/patients_smile.png").default} alt="" />
            </div>
            <div className="pWhat__text">
              <p>
                {_t(
                  "Оценка улыбки это — первый шаг пациента навстречу решению своей ортодонтической проблемы. Оценка улыбки производится врачами ортодонтами и является предварительной консультацией пациента. Вам необходимо в нескольких словах описать проблему и отправить фотографию Вашей улыбки, используя легкую и понятную форму для заполнения. После отправки запроса, в течении трех дней, Вы получите предварительную консультацию врача ортодонта. Специалисты компании Easy Bite не только проконсультируют, но и помогут подобрать врача ортодонта в удобной для Вас локации. Данный запрос не требует оплаты и ни к чему Вас не обязывает. Мы хотим помочь Вам и оставить приятное впечатление о компании Easy Bite.",
                )}
              </p>
              <div className="btnWrap">
                <Button onClick={openCooperationDialog} className="btn">
                  {_t("Оценить улыбку")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="searchDoc">
        <div className="searchDoc__overlay" />
        <div className="searchDoc__content">
          <div className="searchDoc__company">
            <img src={require("../../../assets/img_easy/company.png").default} alt="" />
          </div>
          <div className="searchDoc__text">
            <h2>{_t("Поиск доктора")}</h2>
            <p>
              {_t(
                "Компания Easy Bite готова помочь Вам с поиском квалифицированного врача ортодонта с в Вашем городе и с удобным для Вас расположением.",
              )}
            </p>
            <Button className="btn blue" component={Link} to="/find-doctor">
              {_t("Найти доктора")}
            </Button>
          </div>
          <div className="searchDoc__pic">
            <img src={require("../../../assets/img_easy/patients_doc.png").default} alt="" />
          </div>
        </div>
      </section>
      <AskSmileAssessmentDialog />
    </div>
  );
}

export default ForPatients;
