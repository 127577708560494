import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { throttle } from "lodash";
import { Button } from "@material-ui/core";
import LanguageSelect from "../../LanguageSelect";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PersonPinRoundedIcon from "@material-ui/icons/PersonPinRounded";
import SpeakerNotesRoundedIcon from "@material-ui/icons/SpeakerNotesRounded";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../../assets/img_easy/logo.png";
import "./index.scss";

function Header() {
  const { t: _t } = useTranslation();
  const [sticky, toggleSticky] = useState(false);
  const [nav, setNav] = useState(false);

  useEffect(() => {
    const onScroll = throttle(() => {
      if (window.scrollY >= 150) {
        toggleSticky(true);
      } else {
        toggleSticky(false);
      }
    }, 0);

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <header className={`outerHeader${sticky ? " sticky" : ""}`}>
      <div className="container">
        <Link to="/" className="outerHeader__logo">
          <img src={logo} alt="Easybite" />
        </Link>
        <nav className={`outerHeader__nav${nav ? " opened" : ""}`}>
          <div className="outerHeader__links">
            <NavLink to="/for-patients" activeClassName="active" className="outerHeader__link" onClick={handleNav}>
              <AccountCircleIcon />
              {_t("Для пациентов")}
            </NavLink>
            <NavLink to="/for-doctors" activeClassName="active" className="outerHeader__link" onClick={handleNav}>
              <PersonPinRoundedIcon />
              {_t("Для врачей")}
            </NavLink>
            <NavLink to="/blog" activeClassName="active" className="outerHeader__link" onClick={handleNav}>
              <SpeakerNotesRoundedIcon />
              {_t("Блог")}
            </NavLink>
          </div>
          <LanguageSelect />
          <Button className="outerHeader__login" component={Link} to="/login">
            {_t("Вход")}
          </Button>
          <div className="outerHeader__socials">
            <a href="https://www.facebook.com/" className="outerHeader__social">
              <FacebookIcon />
            </a>
            <a href="https://www.instagram.com/" className="outerHeader__social">
              <InstagramIcon />
            </a>
            <a href="https://www.youtube.com/" className="outerHeader__social">
              <YouTubeIcon />
            </a>
          </div>
        </nav>
        <div className="outerHeader__burger" onClick={handleNav}>
          <MenuIcon />
        </div>
      </div>
    </header>
  );
}

export default Header;
