import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import ContactUs from "./Dialogs/ContactUs";
import "./index.scss";
import { useHistory } from "react-router";
import { COOPERATION_DIALOG_HASH } from "../../../constants";

const Footer = () => {
  const { t: _t } = useTranslation();
  const { push } = useHistory();

  function openCooperationDialog() {
    push({ hash: COOPERATION_DIALOG_HASH });
  }

  return (
    <footer className="outerFooter">
      {/*<Button component={Link} to="/e-contract" target="_blank" rel="noopener noreferrer">*/}
      {/*  {_t("Электронный договор")}*/}
      {/*</Button>*/}
      <Button component={Link} to="/terms-of-use" target="_blank" rel="noopener noreferrer">
        {_t("Условия использования")}
      </Button>
      <Button component={Link} to="/privacy-policy" target="_blank" rel="noopener noreferrer">
        {_t("Политика конфиденциальности")}
      </Button>
      <Button onClick={openCooperationDialog}>{_t("Связаться с нами")}</Button>
      <ContactUs />
    </footer>
  );
};

export default Footer;
