import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import scrollToTop from "../../../modules/scrollToTop";
import "./ForDoctors.scss";

import doc from "../../../assets/img_easy/whatDoc.png";
import why1 from "../../../assets/img_easy/pluses4.png";
import why2 from "../../../assets/img_easy/pluses6.png";
import web from "../../../assets/img_easy/web.png";
import web1 from "../../../assets/img_easy/web1.png";
import web2 from "../../../assets/img_easy/web2.png";
import web3 from "../../../assets/img_easy/web3.png";
import web4 from "../../../assets/img_easy/web4.png";
import web5 from "../../../assets/img_easy/web5.png";
import doc2 from "../../../assets/img_easy/doc2.png";
import trainingIcon from "../../../assets/img_easy/training_icon.png";
import ContactUs from "../Footer/Dialogs/ContactUs";
import { useHistory } from "react-router";
import { COOPERATION_DIALOG_HASH } from "../../../constants";

function ForDoctors() {
  const { t: _t } = useTranslation();

  const { push } = useHistory();

  useEffect(() => {
    scrollToTop();
  }, []);

  function openCooperationDialog() {
    push({ hash: COOPERATION_DIALOG_HASH });
  }

  return (
    <div className="forDoctors">
      <ContactUs />
      <section className="start">
        <div className="container">
          <div className="start__wrap">
            <h1>{_t("Для докторов")}</h1>
            <p>{_t("Проектирование и производство систем элайнеров")}</p>
            <div className="btnWrap">
              <Button className="btn blue" onClick={openCooperationDialog}>
                {_t("Начать сотрудничество с Easy Bite")}
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section className="what">
        <div className="what__wrap">
          <div className="what__pic">
            <img src={doc} alt="" />
          </div>
          <div className="what__text">
            <h2>{_t("Что такое элайнеры Easy Bite")}</h2>
            <p>
              {_t(
                "Элайнеры – это съемные ортодонтические аппараты, которые работают в системе и обеспечивают поэтапное передвижение зубов при своевременной замене. Элайнеры планируются и производятся при помощи 3D технологий, что делает результат более предсказуемым. Капы легко снимаются и одеваются, надёжно фиксируются на зубах. При правильном ношении кап, пациенты останутся довольными конечным результатом. Исправление прикуса элайнерами нашей компании не требует от врачей постоянного контроля за пациентами и частых приемов. Ваши пациенты могут быть уверенны что на каждом этапе лечения будет достигнут максимальный результат.",
              )}
            </p>
          </div>
        </div>
      </section>
      <section className="why">
        <div className="redBox">
          <div className="why__wrap">
            <div className="why__text">
              <h2>{_t("Почему с нами комфортно сотрудничать")}</h2>
              <p>
                {_t(
                  "Ответственный и современный подход к работе в сочетании с четким и уважительным взаимодействием с врачами, делает компанию Easy Bite достойным бизнес партнёром. Для удобства работы наших сотрудников и партнёров разработана специальная веб-платформа, в которой одинаково удобно составлять планы лечения пациентов и осуществлять коммуникацию между докторами. Мы всегда изготавливаем элайнеры в установленный срок и в соответствии с предоставленными исходными данными. Качество материала, применяемого для изготовления нашей продукции, также не вызывает нареканий у докторов, которые выбрали сотрудничество с нами.",
                )}
              </p>
            </div>
            <div className="why__pics">
              <img src={why1} alt="" />
              <img src={why2} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="web">
        <div className="web__pic">
          <img src={web} alt="" />
        </div>
        <div className="container">
          <h2>{_t("Онлайн Веб лаборатория Easy Bite")}</h2>
          <div className="web__items">
            <div className="web__item">
              <img src={web1} alt="" />
              <div className="web__text">
                <p>{_t("Персональный аккаунт доктора")}</p>
                <p>{_t("Быстрая регистрация")}</p>
              </div>
            </div>
            <div className="web__item">
              <img src={web2} alt="" />
              <div className="web__text">
                <p>{_t("3D сетап")}</p>
                <p>{_t("Визуализация планов лечения")}</p>
              </div>
            </div>
            <div className="web__item">
              <img src={web3} alt="" />
              <div className="web__text">
                <p>{_t("Хранение цифровых данных пациента")}</p>
                <p>{_t("Доступ через авторизацию с любого устройства")}</p>
              </div>
            </div>
            <div className="web__item">
              <img src={web4} alt="" />
              <div className="web__text">
                <p>{_t("Онлайн уведомления")}</p>
              </div>
            </div>
            <div className="web__item">
              <img src={web5} alt="" />
              <div className="web__text">
                <p>{_t("Создание документов онлайн")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="big">
        <div className="redBox blueBox">
          <div className="big__wrap">
            <h2>{_t("Как мы помогаем врачам общаться с пациентами")}</h2>
            <p>
              {_t(
                "Используя интернет-платформу от компании Easy Bite врачи могут демонстрировать пациентам планы лечения в максимально понятной для них форме. Благодаря 3D платформе доктор может выполнять визуальный контроль перемещения зубов пациента и наглядно показать ему результат до и после лечения. Она также позволяет детально изучить клинический случай и возможности исправления патологии. Фото и видео отчет, отображающий динамику исправления прикуса, в совокупности со всеми материалами планирования, помогает мотивировать пациента придерживаться врачебных рекомендаций и продолжать лечение. На веб-платформе можно создать ссылку для удаленного просмотра проекта ортодонтического лечения. Это облегчает коммуникацию с пациентом на расстоянии, и делает возможной консультацию вне клиники.",
              )}
            </p>
          </div>
        </div>
        <div className="redBox">
          <div className="big__wrap big__wrap_red">
            <img src={doc2} alt="" className="big__pic" />
            <div className="big__text">
              <h2>{_t("Большой ортодонтический опыт")}</h2>
              <p>
                {_t(
                  "Для врачей, которые уже сотрудничают с Easy Bite  на вопрос что лучше посоветовать пациенту, элайнеры или брекеты, ответ очевиден. Качественный полимерный материал из которого изготавливаются элайнеры Easy Bite отлично сохраняет заданную форму. Большой опыт и высококвалифицированная команда гарантируют качественный результат ортодонтического лечения.",
                )}
              </p>
              <div className="btnWrap">
                <Button className="btn blue" onClick={openCooperationDialog}>
                  {_t("Начать сотрудничество с Easy Bite")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="training">
        <div className="container">
          <h2>{_t("Обучение и сертификация")}</h2>
          <div className="training__icon">
            <img src={trainingIcon} alt="" />
          </div>
          <p>
            {_t(
              "Компания Easy Bite регулярно проводит курсы для докторов по обучению работе с элайнерами. Узнать о ближайших мероприятиях Вы можете посетив наш блог.",
            )}
          </p>
          <div className="btnWrap">
            <Button className="btn blue" component={Link} to="/blog">
              {_t("Перейти в блог")}
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ForDoctors;
