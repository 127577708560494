import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import scrollToTop from "../../../modules/scrollToTop";
import AskSmileAssessmentDialog from "../ForPatients/AskSmileAssessmentDialog";
import compName from "../../../assets/img_easy/compName.png";
import pluses1 from "../../../assets/img_easy/pluses1.png";
import pluses2 from "../../../assets/img_easy/pluses2.png";
import pluses3 from "../../../assets/img_easy/pluses3.png";
import pluses4 from "../../../assets/img_easy/pluses4.png";
import pluses5 from "../../../assets/img_easy/pluses5.png";
import pluses6 from "../../../assets/img_easy/pluses6.png";
import "./index.scss";

function HomePage() {
  const { t: _t } = useTranslation();

  useEffect(() => {
    scrollToTop();
  }, []);

  const [isAskSmileAssessmentDialogOpen, setIsAskSmileAssessmentDialogOpen] = useState(false);
  function openAskSmileAssessmentDialog() {
    setIsAskSmileAssessmentDialogOpen((prev) => !prev);
  }

  return (
    <div className="homePage">
      <section className="mainScreen">
        <div className="mainScreen__wrap">
          <img src={compName} alt="" />
          <h1>{_t("Система элайнеров")}</h1>
        </div>
      </section>
      <section className="pluses">
        <div className="pluses__items">
          <div className="pluses__item">
            <div className="pluses__pic">
              <img src={pluses1} alt="" />
            </div>
            <p className="pluses__text">{_t("Современное ортодонтическое лечение")}</p>
          </div>
          <div className="pluses__item">
            <div className="pluses__pic">
              <img src={pluses2} alt="" />
            </div>
            <p className="pluses__text">{_t("Безопасно для здоровья зубов")}</p>
          </div>
          <div className="pluses__item">
            <div className="pluses__pic">
              <img src={pluses3} alt="" />
            </div>
            <p className="pluses__text">{_t("Высокая квалификация специалистов")}</p>
          </div>
        </div>
        <div className="pluses__find">
          <h2>{_t("Узнайте подойдут ли Вам элайнеры")}</h2>
          <div className="btnWrap">
            <Button onClick={openAskSmileAssessmentDialog} className="btn red">
              {_t("Оценить улыбку")}
            </Button>
          </div>
        </div>
      </section>
      <section className="nec">
        <h2>{_t("Зачем нужно применять ортодонтическое лечение")}</h2>
        <p>
          {_t(
            "Неправильный прикус и кривые зубы негативно сказываются как на визуальной привлекательности пациента, так и на его здоровье. Причиной возникновения дефектов речи, неприятного запаха изо рта и заболеваний пищеварительной системы часто являются именно проблемы с зубами. Поэтому, ортодонтическое лечение зубов является необходимой мерой, которая предотвращает возникновение различных заболеваний и позволяет обрести здоровую и красивую улыбку.",
          )}
        </p>
      </section>
      <section className="why">
        <div className="container">
          <h2>{_t("Почему элайнеры")}</h2>
          <p>
            {_t(
              "Современные системы элайнеров уже практически не уступают в эффективности исправления прикуса и выравнивания зубов брекет системам и при этом полностью лишены их недостатков. Элайнеры, в отличие от брекетов, не травмируют ротовую полость, не препятствуют приему пищи и проведению гигиенических процедур, не вызывают физический дискомфорт.",
            )}
          </p>
          <p>
            {_t(
              "Системы элайнеров незаметны на зубах, что делает ортодонтическое лечение эстетически приятным. При соблюдении врачебных рекомендаций, частое посещение стоматолога не требуется и количество контрольных визитов может сократиться до 1 раза в 6-8 недель.",
            )}
          </p>
        </div>
      </section>
      <section className="us">
        <h2>{_t("Почему элайнеры Easy Bite")}</h2>
        <p>
          {_t(
            "Врачи и их пациенты выбирают системы элайнеров Easy Bite, потому что они изготавливаются из прочного и гипоаллергенного полимерного материала, гораздо лучше удерживающего необходимую форму. Элайнеры практически не подвергаются окрашиванию и повреждению при повседневном использовании. Эффективное выравнивание зубов без брекетов благодаря компании Easy Bite теперь доступно для всех желающих обрести красивую и здоровую улыбку. При правильном ношении элайнеры Easy Bite способны исправить практически любой клинический случай в максимально сжатые сроки.",
          )}
        </p>
      </section>
      <section className="pluses2">
        <div className="pluses2__item">
          <div className="pluses2__pic">
            <img src={pluses4} alt="" />
          </div>
          <p>{_t("Незаметны на зубах")}</p>
        </div>
        <div className="pluses2__item">
          <div className="pluses2__pic">
            <img src={pluses5} alt="" />
          </div>
          <p>{_t("Нет ограничений в еде")}</p>
        </div>
        <div className="pluses2__item">
          <div className="pluses2__pic">
            <img src={pluses6} alt="" />
          </div>
          <p>{_t("Безопасны для эмали")}</p>
        </div>
      </section>
      <section className="about">
        <h2>{_t("О нашей компании")}</h2>
        <p>
          {_t(
            "Компания Easy Bite создана ортодонтами для ортодонтов. В работе мы используем 15 летний опыт в перемещении зубов с помощью технологии Set-up. Для производства самих элайнеров используем сертифицированный материал компании Erkodent (Германия). Для уменьшения времени лечения в некоторых клинических случаях мы используем элайнеры по технологии Lamitec с ортодонтическими винтами.",
          )}
        </p>
      </section>
      <AskSmileAssessmentDialog
        isAskSmileAssessmentDialogOpen={isAskSmileAssessmentDialogOpen}
        openAskSmileAssessmentDialog={openAskSmileAssessmentDialog}
      />
    </div>
  );
}

export default HomePage;
