import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { API } from "../../../modules/Api";
import scrollToTop from "../../../modules/scrollToTop";
import "./index.scss";

function FindDoctor() {
  const { t: _t } = useTranslation();

  useEffect(() => {
    scrollToTop();
  }, []);

  const [doctorsList, setDoctorsList] = useState([]);
  useEffect(() => {
    let isSubscribed = true;
    API.get("/get_doctors").then(
      (response) => {
        if (isSubscribed) {
          setDoctorsList(response.data.results);
        }
      },
      (error) => {
        console.log(error);
      },
    );
    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <div className="findDoctor">
      <section className="findDoctor__header">
        <h1>{_t("Найти врача")}</h1>
        <p>{_t("Найдите сертифицированного врача EASY BITE в своем городе и ближайшем районе.")}</p>
      </section>
      {doctorsList.map((item) => (
        <div className="findDoctor__items" key={item.id}>
          <div className="findDoctor__item">
            <div className="findDoctor__pic">
              <img src={item.avatar} alt="" />
            </div>
            <div className="findDoctor__text">
              <h6>{item.name}</h6>
              <p>{item.clinic}</p>
              <p>{item.country}</p>
              <p>{item.phone_number}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default FindDoctor;
