import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Route, Switch } from "react-router";
import HomePage from "../../components/LoginLayout/HomePage";
import ForDoctors from "../../components/LoginLayout/ForDoctors/ForDoctors";
import ForPatients from "../../components/LoginLayout/ForPatients/ForPatients";
import FindDoctor from "../../components/LoginLayout/FindDoctor";
import Blog from "../../components/LoginLayout/Blog";
import Header from "../../components/LoginLayout/Header";
import Footer from "../../components/LoginLayout/Footer";

class LoginLayout extends PureComponent {
  render() {
    return (
      <div className="loginLayout">
        <Header />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/for-doctors" component={ForDoctors} />
          <Route exact path="/for-patients" component={ForPatients} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/find-doctor" component={FindDoctor} />
        </Switch>
        <Footer />
      </div>
    );
  }
}

LoginLayout.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(LoginLayout);
