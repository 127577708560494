import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getBlogList } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import scrollToTop from "../../../modules/scrollToTop";
import "./index.scss";

function Blog() {
  const { t: _t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    dispatch(getBlogList(1));
  }, [dispatch]);

  const { blogList } = useSelector(({ addArticleToBlog }) => ({
    blogList: addArticleToBlog.blogList,
  }));

  return (
    <div className="blog">
      <section className="blog__header">
        <h1>{_t("Блог")}</h1>
      </section>
      <section className="blog__articlesWrap">
        {blogList.map((el) => (
          <div key={el.id} className="blog__article">
            <div className="blog__head">
              <h3>{_t("Статья")}</h3>
              <div className="blog__date">
                {_t("создано на")}: {el.created_at}
              </div>
            </div>
            <div className="blog__body">
              <h2 className="blog__title">{el.header}</h2>
              <p>{el.text}</p>
              <img src={el.image} alt="" />
            </div>
          </div>
        ))}
      </section>
    </div>
  );
}

export default Blog;
