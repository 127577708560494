import React, { PureComponent } from "react";
import i18next, { availableLanguages } from "../../i18n";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import { Language } from "@material-ui/icons";
import "./index.scss";

class LanguageSelect extends PureComponent {
  state = {
    language: i18next.language,
  };
  componentDidMount() {
    if (i18next.language) {
      this.setState({ language: i18next.language });
    }
  }

  changeLanguage = (e) => {
    this.setState(
      {
        language: e.target.value,
      },
      () => {
        i18next.changeLanguage(this.state.language);
      },
    );
  };

  render() {
    const { language } = this.state;
    return (
      <>
        <FormControl className="lang">
          <Language style={{ marginRight: "5px" }} className="icon-lang" />
          <Select className="select" value={language} onChange={this.changeLanguage}>
            {availableLanguages.map((lang) => (
              <MenuItem key={lang.key} value={lang.key} className="option">
                {lang.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  }
}

export default LanguageSelect;
