import React, { useEffect, useRef } from "react";

function ImageCropper({ className, src }) {
  const canvasRenderer = useRef(null);

  useEffect(() => {
    let isSubscribed = true;

    const ctx = canvasRenderer.current.getContext("2d");
    const image = new Image();
    image.onload = () => {
      if (isSubscribed) {
        const naturalWidth = image.naturalWidth;
        const naturalHeight = image.naturalHeight;

        const targetWidth = 400;
        const targetHeight = 400;

        const cropRatioX = targetWidth / naturalWidth;
        const cropRatioY = targetHeight / naturalHeight;
        const cropRate = Math.min(cropRatioX, cropRatioY);

        const croppedWidth = naturalWidth * cropRate;
        const croppedHeight = naturalHeight * cropRate;

        if (canvasRenderer.current) {
          canvasRenderer.current.width = croppedWidth;
          canvasRenderer.current.height = croppedHeight;
        } else {
          return;
        }

        ctx.drawImage(image, 0, 0, croppedWidth, croppedHeight);
      }
    };
    image.src = src;
    return () => {
      isSubscribed = false;
    };
  }, [src]);

  return <canvas ref={canvasRenderer} className={className} />;
}

export default ImageCropper;
